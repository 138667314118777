<template>
  <v-container>

    <div class="d-flex flex-row justify-between align-center">
      <h3>{{ $t("message.title-approved-merchant") }}</h3>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>

      <!-- Routing to Approve list -->
      <template>
        <router-link to="/appliedMerchant">
          <v-row>
            <v-btn rounded color="primary">
              {{ $t("message.btn-applied-list") }}
            </v-btn>
          </v-row>
        </router-link>
      </template>
    </div>
    <!-- <v-toolbar flat>
      <h3>{{ $t("message.title-approved-merchant") }}</h3>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>

      <template>
        <router-link to="/appliedMerchant">
          <v-row>
            <v-btn rounded color="primary">
              {{ $t("message.btn-applied-list") }}
            </v-btn>
          </v-row>
        </router-link>
      </template>
    </v-toolbar> -->

    <FilterApproved />

    <v-pagination
    v-if="!isLength"
    v-model="page"
    :length="lengthPage"
    :total-visible="7"
    @input="changePage"
    ></v-pagination>

    <!-- data table -->
    <v-data-table
      :headers="headers"
      :items="resultApprove"
      :hide-default-footer="true"
      :items-per-page="approveHeader.per_page"
      class="elevation-0"
      disable-sort
      justify="center"
      :server-items-length="approveHeader.total"
      :options.sync="option"
      :footer-props="{
        'items-per-page-options': [ approveHeader.per_page != null ? approveHeader.per_page : 5, ],
      }"
      :page="approveHeader.currentPage"
    >
      <!-- action table -->
      <template v-slot:[`item.action`]="{ item }">
        <v-icon
          color="indigo"
          small
          class="mr-2"
          @click.prevent="buttonDetail(item.id)"
        >
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import FilterApproved from "../components/FilterApproved.vue";

export default {
  name: "ApprovedMerchant",
  components: {
    FilterApproved,
  },
  data: () => ({
    page: 1,
    headerList: [],
    option: {},
  }),
  methods: {
    changePage(event) {
      return this.$store.dispatch("approve_module/fetchApprove", {
        page: event
      })
    },
    dispatchApprove() {
      let setPage = parseInt(this.$store.state.approve_module.pageState)
      this.page = parseInt(this.$store.state.approve_module.pageState)
      this.$store.dispatch("approve_module/fetchApprove", { page: setPage });
    },
    buttonDetail(id) {
      this.$router.push(`/detailApproved/${id}`);
    },
  },
  computed: {
    isLength() {
      return this.$store.state.approve_module.isLength
    },
    lengthPage() {
      return this.$store.state.approve_module.length_page
    },
    approveHeader() {
      return this.$store.state.approve_module.approveList_header
    },
    headers() {
      return [
        {
          text: this.$t("message.label-company"),
          align: "start",
          sortable: false,
          value: "company_name",
        },
        { text: this.$t("message.label-merchant"), value: "merchant_name" },
        { text: this.$t("message.label-pic"), value: "pic_name" },
        { text: this.$t("message.label-phone"), value: "phone" },
        { text: this.$t("message.label-address"), value: "address" },
        { text: this.$t("message.label-action"), value: "action" },
      ];
    },
    resultApprove() {
      return this.$store.state.approve_module.resultApprove;
    },
  },
  created() {
    this.dispatchApprove();
  },
  watch: {
    // option: {
    //   handler() {
    //     this.dispatchApprove();
    //   },
    //   deep: true,
    // },
    approveList: {
      handler() {
        this.page = parseInt(this.$store.state.approve_module.pageState)
      },
      deep: true,
    }
  },
};
</script>

<style>
.text .text-merchant {
  font-weight: bold;
  font-size: 16px;
  color: white;
}
.card-merchant {
  width: fit-content !important;
}
.v-card > .v-card__text {
  padding: 8px !important;
}
.v-card {
  width: fit-content !important;
}
.v-application--is-ltr .v-data-footer__select {
  margin-left: 0px !important;
}
.v-data-footer-top {
  border-top: none !important;
}
</style>

<template>
  <v-container>
    <form @submit.prevent="submitFilter">

    <v-row no-gutters align="center" justify="space-between">
      <v-col class="pr-2 pb-2" cols="12" md="2" xs="4">
        <span class="p-0 m-0">{{ $t("message.label-company") }}</span>
        <v-text-field
          class="p-0 mt-1"
          clearable
          outlined
          v-model="filterApprovedList.company"
        ></v-text-field>
      </v-col>
      <v-col class="pr-2 pb-2" cols="12" md="2" xs="4">
        <span class="p-0 m-0">{{ $t("message.label-pic") }}</span>
        <v-text-field
          class="p-0 mt-1"
          clearable
          outlined
          v-model="filterApprovedList.pic"
        ></v-text-field>
      </v-col>
      <v-col class="pr-2 pb-2" cols="12" md="2" xs="4">
        <span class="p-0 m-0">{{ $t("message.label-phone") }}</span>
        <v-text-field
          class="p-0 mt-1"
          clearable
          outlined
          v-model="filterApprovedList.phone"
        ></v-text-field>
      </v-col>
      <v-col class="pr-2 pb-2" cols="12" md="2" xs="4">
        <span class="p-0 m-0">{{ $t("message.label-address") }}</span>
        <v-text-field
          class="p-0 mt-1"
          clearable
          outlined
          v-model="filterApprovedList.address"
        ></v-text-field>
      </v-col>
      <v-col class="pr-2 pb-2" cols="12" md="2" xs="4">
        <v-btn
          rounded
          type="submit"
          color="primary"
          style="float: right;"
        >
          <v-icon left>
            {{ iconSearch }}
          </v-icon>
          {{ $t("message.btn-search") }}
        </v-btn>
      </v-col>
    </v-row>
    </form>
  </v-container>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
export default {
  name: "FilterApproved",
  data: () => ({
    iconSearch: mdiMagnify,
    filterApprovedList: {
      company: "",
      pic: "",
      address: "",
      phone: "",
    },
  }),
  methods: {
    submitFilter() {
        if (!this.filterApprovedList.company) this.filterApprovedList.company = "" 
        if (!this.filterApprovedList.phone) this.filterApprovedList.phone = "" 
        if (!this.filterApprovedList.pic) this.filterApprovedList.pic = "" 
        if (!this.filterApprovedList.address) this.filterApprovedList.address = "" 

        this.$store.dispatch("approve_module/fetchApprove", {
        company: this.filterApprovedList.company === null ? "" : this.filterApprovedList.company,
        phone: this.filterApprovedList.phone === null ? "" : this.filterApprovedList.phone,
        pic: this.filterApprovedList.pic === null ? "" : this.filterApprovedList.pic,
        address: this.filterApprovedList.address === null ? "" : this.filterApprovedList.address,
      });
    },
    initFilterApprove() {
      this.filterApprovedList.company = this.$store.state.approve_module.company;
      this.filterApprovedList.phone = this.$store.state.approve_module.phone;
      this.filterApprovedList.pic = this.$store.state.approve_module.pic;
      this.filterApprovedList.address = this.$store.state.approve_module.address;
    },
  },
  computed: {},
  created() {
    this.initFilterApprove()
  },
};
</script>

<style>
v-data-table >>> div > table > tr {
  border-spacing: 5px;
}
thead {
  background: #3949ab !important;
  text-transform: capitalize;
}
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
tbody tr:hover:not(.v-table__expanded__content) {
  background: #c5cae9 !important;
}
.btn-search {
  border-radius: 20px;
}
.v-input .v-input__control .v-text-field__details {
  /* display: none; */
}
.v-text-field--enclosed .v-input__prepend-inner {
  margin-top: 0px !important;
  align-self: center !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}
.v-text-field--enclosed .v-input__append-inner,
.v-text-field--enclosed .v-input__append-outer {
  margin-top: 0px !important;
  align-self: center !important;
}
</style>
